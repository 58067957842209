@import "defines";
@import "colors";

// Post images
.post-image {
	display: block;
	margin-left: auto;
    margin-right: auto;

    max-width: 100%;
    max-height: 75vh;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: lightgrey;
    margin-bottom: 30px;
}

.post-footer-grid {
    display: grid;
    grid-template-columns: 150px 1fr;
    align-items: center;
    grid-gap: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 50px;
}

.avatar {
    //border-radius: 5px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

@media screen and (max-width: $ipad-width) {

	.post-footer-grid {
		max-width: 400px;
		margin: auto;
		grid-template-columns: 1fr;
		justify-items: center;
		grid-gap: 4px;
	}

	.avatar {
		width: 100px;
		height: 100px;
	}
}

// Blue Box
.blue-box {
    background-color: #CCE3EB;
    padding: 8px 30px;
    border-color: #5294D1;
    border-style: solid;
    border-radius: 10px;
    font-size: 90%;
}

// Inline code

code {
	background-color: #f2f2f2;
 	padding: 2px 4px;
 	border-radius: 3px;
}

// Plain code

.plain-code {
	background-color: #f2f2f2;
	padding: 20px;
	border-radius: 10px;
    overflow-x: scroll;

	// pre {
	// 	font-family: $mono-font-stack;
	// 	font-size: smaller;
	// }
}

// Highlighted code

.highlight {
	background-color: $gray-dark;
	//padding: 16px 0;
	border-radius: 10px;
	overflow: auto;
	padding: 0.5em 1em;

	margin-bottom: 1em;

	code {
    	color: $gray-light;
    	background: transparent !important;
    	font-family: $mono-font-stack;       
    	font-size: smaller;

    }
}

@media screen and (max-width: $ipad-width) {
	.highlight {
		padding: 0 0.4em;
	}
}

.highlight {
    ::selection {
        color: inherit;
    }

    ::-moz-selection {
        color: inherit;
    }

    .hll,
    .s,
    .sa,
    .sb,
    .sc,
    .dl,
    .sd,
    .s2,
    .se,
    .sh,
    .si,
    .sx,
    .sr,
    .s1,
    .ss {
        color: $yellow;
    }

    .go {
        color: $gray;
    }

    .err,
    .g,
    .l,
    .n,
    .x,
    .p,
    .ge,
    .gr,
    .gh,
    .gi,
    .gp,
    .gs,
    .gu,
    .gt,
    .ld,
    .no,
    .nd,
    .ni,
    .ne,
    .nn,
    .nx,
    .py,
    .w,
    .bp {
        color: $gray-light;
    }

    .gh,
    .gi,
    .gu {
        font-weight: bold;
    }

    .ge {
        text-decoration: underline;
    }

    .bp {
        // font-style: italic;
    }

    .c,
    .ch,
    .cm,
    .cpf,
    .c1,
    .cs {
        color: $blue;
    }

    .kd,
    .kt,
    .nb,
    .nl,
    .nv,
    .vc,
    .vg,
    .vi,
    .vm {
        color: $cyan;
    }

    .kd,
    .nb,
    .nl,
    .nv,
    .vc,
    .vg,
    .vi,
    .vm {
        // font-style: italic;
    }

    .na,
    .nc,
    .nf,
    .fm {
        color: $orange;
    }

    .k,
    .o,
    .cp,
    .kc,
    .kn,
    .kp,
    .kr,
    .nt,
    .ow {
        color: $pink;
    }

    .m,
    .mb,
    .mf,
    .mh,
    .mi,
    .mo,
    .il {
        color: $purple;
    }

    .gd {
        color: $red;
    }
}