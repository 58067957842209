@import "home";
@import "post";
@import "defines";
@import "colors";

html {
	background-color: #fefefe;
	color: $text-color-default;
	font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, sans-serif;
	font-size: 120%;
	line-height: 1.5;
    margin-left: 30px;
    margin-right: 30px;
}

@media screen and (max-width: $ipad-width) {
  html {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 110%;
  }
}

@media screen and (max-width: $phone-width) {
  html {
    margin-left: 18px;
    margin-right: 18px;
  }
}

@media screen and (max-width: $smallphone-width) {
  html {
    margin-left: 10px;
    margin-right: 10px;
  }
}

nav {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

nav a {
	padding-right: 20px;
	padding-left: 20px;
	text-decoration: none;
	color: $text-color-light;
	line-height: 2em;
}

body {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

.site-title {
	text-align: center;
	font-size: 300%;
}

@media screen and (max-width: $ipad-width) {
  .site-title {
        font-size: 300%;
    }
}

@media screen and (max-width: $bigphone-width) {
  .site-title {
        font-size: 200%;
    }
}

.current-nav-item {
	text-decoration: underline;
}

a {
    color: inherit;
}

h1 {
    font-size: 200%;
}

@media screen and (max-width: $ipad-width) {
  h1 {
        font-size: 150%;
    }
}

@media screen and (min-width: $ipad-width) { 
  .phone-size-only { 
    display: none; 
  } 
}



