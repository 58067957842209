// Text Colors
$text-color-default: #333333;
$text-color-light: #505050;
$text-color-very-light: #7D7D7D;

// Text Color classes
.text-color-default {
	color: $text-color-default;
}

.text-color-light {
	color: $text-color-light;
}

.text-color-very-light {
	color: $text-color-very-light;
}