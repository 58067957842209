@import "defines";
@import "colors";

.post-index-title {
	text-decoration: none;
}

.post-index-title:hover {
	text-decoration: underline;
}

.post-index-date {
	color: $text-color-very-light;
	font-size: 80%;
	margin-left: 10px;
}

@media screen and (max-width: $phone-width) {
	.post-index-date {
		display: block;
		margin-left: 0;
	}
}

.continue-reading {
	text-decoration: none;
	color: $text-color-very-light;
}

.continue-reading:hover {
	text-decoration: underline;
}